import React, { useState, useEffect } from 'react'; 
import axios from 'axios'
import styled from 'styled-components'
import { ClickAwayListener } from "@material-ui/core";
import {
  Link, 
  LinkWrapper, 
  VerticalLine, 
  Notification, 
  Notifications, 
  NotificationWrapper
} from '../styled_components/header/box'
import user_change_ico from 'ico_user.svg';
import comment_mention_ico from 'ico_msg.svg';
import card_alteration_ico from 'ico_change.svg';
import { GrayLink } from '../styled_components/default'

const NotificationComponent = ({currentCompanyId, setCurrentCompany}) => {
  const [notificationVisiblity, setNotificationVisibility] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [nonReadCount, setNonReadCount] = useState(0);
  const [visibleBoards, setVisibleBoards] = useState(false);
  const [selectedTab, setSelectedTab] = useState('todas');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const toggleBoardVisibility = (companyName, boardName) => {
    setVisibleBoards(prevState => ({
      ...prevState,
      [`${companyName}-${boardName}`]: !prevState[`${companyName}-${boardName}`]
    }));
  };

  const fetchNotifications = async() => {
    const result = await axios.get(`/notifications.json`);

    if(result.data) {
      const noReadLength = result?.data?.data?.filter((n)=>( !n.attributes?.already_opened)).length 
      setNotifications(result?.data?.data)
      setNonReadCount(noReadLength)
    }
  }

	const markAllVisited = async () => {
		if(!window.confirm('Tem certeza que deseja marcar todas como lidas?')) {
			return
		}

		try {
			const response = await axios.post(`/notifications/mark-all-visited`)
      await fetchNotifications();
		} catch {
      console.error('Error marking notifications as visited:', err);
		}
	}

  useEffect(() => {
    fetchNotifications()
  }, [])

  const notificationTypeIco = (notificationType) => {
    switch (notificationType) {
      case 'comment_mention':
        return comment_mention_ico
      case 'card_alteration':
        return card_alteration_ico
      default:
        return user_change_ico
    }
  }

  const showNotifications = () => {
    setNotificationVisibility(prev => !prev)
    axios.post(`/notifications/opened.json`)
  }

  const ellipsisSubstring = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "..."
    }else{
      return str
    }
  }

  const goToNotification = (id, notificationCompanyId) => {
    let url = `/notifications/${id}/visit`
    if (currentCompanyId != notificationCompanyId) {
      setCurrentCompany(notificationCompanyId, url)
    }else{
      Turbolinks.visit(url)
    }
  }

  const countUnreadNotifications = () => {
    return notifications.filter(n => !n.attributes.visited).length;
  }

  const groupedNotifications = notifications?.reduce((groups, notification) => {
    const companyName = notification.attributes?.company?.name;
    const boardName = notification.attributes?.board;
    const cardName = notification.attributes?.card?.title;

    groups[companyName] ||= {};
    groups[companyName][boardName] ||= {};
    groups[companyName][boardName][cardName] ||= [];

    groups[companyName][boardName][cardName].push(notification);

    return groups;
}, {});
  console.log("groups", groupedNotifications)
  console.log("notifications", notifications)

  return (
    <LinkWrapper style={{ zIndex: 5, position: 'relative' }}>
      <div onClick={() => showNotifications()}>
        <i className={`fa fa-bell`} style={{ color: '#676767' }} />
        <Link>Notificações</Link>
      </div>
      {nonReadCount > 0 && (
        <NonReadNotificationsCounter>
          {nonReadCount}
        </NonReadNotificationsCounter>
      )}
      {notificationVisiblity && (
        <NotificationWrapper>
          <ClickAwayListener onClickAway={() => setNotificationVisibility(false)}>
            <div>
            <Notifications alignRight={true} width={800} tabIndex="0">
              {countUnreadNotifications() === 0 ? (
                <NoNotificationsMessage>
                  Você não tem nenhuma notificação
                </NoNotificationsMessage>
              ) : (
              <TabBar>
                <Tab
                  style={{color: '#31b7bc', fontSize: 15}}
                 >
                  Não lidas
                </Tab>
                <Tab style={{ fontSize: 12}} onClick={markAllVisited}>
                  Marcar todas como lidas
                </Tab>
              </TabBar>)}
              <div style={{display: 'flex', flexDirection: 'column', overflowY: 'scroll', maxHeight: 627}}>
                <div>
                  {Object.keys(groupedNotifications).map((companyName) => {
                    let hasNotificationForCompany = false
                    Object.keys(groupedNotifications[companyName]).forEach((boardName) => {
                      hasNotificationForCompany ||= Object.keys(groupedNotifications[companyName][boardName])
                        .some((cardName) =>{
                          let aparece = groupedNotifications[companyName][boardName][cardName].filter((x)=> !x.attributes.visited).length > 0
                          return aparece
                        });
                    });

                    return hasNotificationForCompany && <div key={companyName} style={{ marginBottom: '16px', overflow: 'auto'}}>
                      <CompanyName>{companyName}</CompanyName>
                      {Object.keys(groupedNotifications[companyName]).map((boardName) => (
                        <>{Object.values(groupedNotifications[companyName][boardName]).flat()
                          .filter((n) => n?.attributes?.visited !== true).length > 0 && <div key={boardName}>
                          <BoardName>
                            <>
                              <GrayBall>{Object.values(groupedNotifications[companyName][boardName]).flat()
                                .filter((n) => n?.attributes?.visited !== true).length}
                              </GrayBall>
                              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <div style={{paddingLeft: 10}}>
                                  <div>{boardName === "null" ? "Seção Resultados" : "Plano de Ação:"}</div>
                                  <div style={{fontWeight: 500}}>{boardName === "null" ? "Mensagens" : boardName}</div>
                                </div>
                                <div onClick={() => toggleBoardVisibility(companyName, boardName)} style={{
                                    paddingRight: 10,
                                    fontWeight: 500,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  {`Ver ${!visibleBoards[`${companyName}-${boardName}`] ? "mais" : "menos"}`}
                                  <i className={`fa fa-chevron-${!visibleBoards[`${companyName}-${boardName}`]  ? "down" : "up"}`} style={{color: '#676767'}}/>
                                </div>
                              </div>
                            </>
                          </BoardName>

                          {visibleBoards[`${companyName}-${boardName}`] && (
                            Object.keys(groupedNotifications[companyName][boardName]).map((cardName) => (
                              <div key={cardName}>
                                <CardName>{cardName}</CardName>
                                {groupedNotifications[companyName][boardName][cardName]
                                  .filter(notification => selectedTab === 'todas' || !notification.attributes.visited)
                                  .slice(0, 100)
                                  .map((notification) => {
                                    const attr = notification.attributes;
                                    return (
                                      <Notification
                                        key={notification.id}
                                        visited={attr.visited}
                                        padding={14}
                                        onClick={() => goToNotification(attr.id, attr?.company?.id)}
                                        style={{ marginBottom: '8px' }}
                                      >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <img
                                            style={{ width: 40, marginRight: 16 }}
                                            src={notificationTypeIco(attr?.notification_type)}
                                            alt=""
                                          />
                                          <div>
                                            <NotificationDescription
                                              dangerouslySetInnerHTML={{
                                                __html: ellipsisSubstring(
                                                  attr.description + (attr.card ? `<strong style="color: #31b7bc">- ${attr.card.title}</strong>` : ""), 350),
                                              }}
                                            />
                                          </div>
                                          <div>
                                            {attr.visited ? <></> : <GreenBall></GreenBall>}
                                          </div>
                                        </div>
                                      </Notification>
                                    );
                                  })}
                              </div>
                            ))
                          )}

                        </div>}</>
                      ))}
                    </div>
                  })}
                </div>
                <GrayLink href="/notifications" style={{
                  bottom: 0,
                  backgroundColor: '#fff', 
                  padding: 10,
                  textAlign: 'center',
                }}>Ver todas as notificações</GrayLink>
              </div>
            </Notifications>
            
            </div>
          </ClickAwayListener>
        </NotificationWrapper>
      )}
    </LinkWrapper>
  )
}

export default NotificationComponent;

const NotificationDescription = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: #617e94  ;
  margin-bottom 10px;
`

const GrayBall = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #617E94;
  height: 23px;
  width: 24px;
  font-size: 9px;
`
const GreenBall = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #31b7bc;
`
const NotificationDate = styled.div`
 flex-grow: 0;
 font-family: Roboto;
 font-size: 10px;
 letter-spacing: 0.5px;
 text-align: left;
 color: #617e94;
 margin-bottom: 10px;
`
const NonReadNotificationsCounter = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background: #ff0000;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  font-weight: bold;
`
const CompanyName = styled.div`
  display: flex; 
  align-items: center;
  padding-left: 50px;
  color: white;
  background: #31b7bc;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
`
const BoardName = styled.div`
  display: flex;
  align-items: center;
  color: #617E94;
  background: #E5E5E5;
  height: 40px;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-align: left;
  padding-left: 15px;
`

const CardName = styled.div`
	font-family: Roboto;
	font-size: 15px;
	font-weight: 700;
	letter-spacing: 0.05em;
	text-align: left;
	color: #617E94;
  display: flex;
  padding: 12px 0px 0px 50px;
  border-bottom: 1px solid #e5e5e5;
`

const TabBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 15px;
`;

const Tab = styled.div`
  cursor: pointer;
  font-weight: bold;
  color: #617E94;
`;

const Separator = styled.div`
  border-left: solid 2px;
  margin-left: 10px;
  margin-right: 10px;
  color: #777;
}
`

const Section = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
`;

const SectionTitle = styled.div`
  font-weight: bold;
  color: #00796b;
  margin-bottom: 8px;
`;

const NoNotificationsMessage = styled.div`
  padding: 20px;
  background-color: #f9fafc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  text-align: center;
  margin-top: 20px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
