import React from 'react';
import { FixedSizeList as List } from 'react-window';

const Row = ({ index, style, data, numberToCurrency }) => {
  const row = data[index];
  return (
    <div style={{ ...style, display: 'flex' }}>
      <div style={{ flex: 1, padding: '8px' }}>{row.attributes.date}</div>
      <div style={{ flex: 1, padding: '8px' }}>{row.attributes.cc_code}</div>
      <div style={{ flex: 2, padding: '8px' }}>{row.attributes.description_cc}</div>
      <div style={{ flex: 3, padding: '8px' }}>{row.attributes.observation}</div>
      <div style={{ flex: 2, padding: '8px' }}>{row.attributes.provider}</div>
      <div style={{ flex: 1, padding: '8px', width: 100 }}>{numberToCurrency(row.attributes.credit - row.attributes.debit)}</div>
    </div>
  );
};

const FastTable = ({ filteredRows, numberToCurrency }) => (
  <div style={{ overflow: 'auto' }}>
    <div style={{ display: 'flex', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
      <div style={{ flex: 1, padding: '8px' }}>Data</div>
      <div style={{ flex: 1, padding: '8px' }}>Código centro de custo</div>
      <div style={{ flex: 2, padding: '8px' }}>Descrição centro de custo</div>
      <div style={{ flex: 3, padding: '8px' }}>Observação</div>
      <div style={{ flex: 2, padding: '8px' }}>Fornecedor</div>
      <div style={{ flex: 1, padding: '8px', width: 100 }}>Valor</div>
    </div>
    <List
      height={window.innerHeight - 400} // Altura calculada
      itemCount={filteredRows.length}
      itemSize={35} // Altura de cada linha
      width="100%"
      itemData={filteredRows}
    >
      {({ index, style }) => (
        <Row
          index={index}
          style={style}
          data={filteredRows}
          numberToCurrency={numberToCurrency}
        />
      )}
    </List>
  </div>
);

export default FastTable;
