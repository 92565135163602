import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import axios from 'axios';
import { FormControl, InputLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Fuse from 'fuse.js';
import FastTable from './FastTable';

export default function LedgerModal(props) {
  const [open, setOpen] = useState(false);
  const [ledgerRows, setLedgerRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchLedgerData();
    }
  }, [open]);

  function fetchLedgerData() {
    let { year, month, account, despesa_id, range, is_fixed } = props;
    setLoading(true)
    let params = {
      year,
      account,
      despesa_id,
      is_fixed
    }
    if(range){
      params.range = range
    }else{
      params.month = month
    }

    axios.get(`/ledger_rows`, {
      params,
    })
    .then(function (response) {
      setLedgerRows(response.data.data);
      setLoading(false)
    });
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function numberToCurrency(number = 0){
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0;
    }

    return parseFloat(number).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }

  // Filtro com Fuse.js
  const filteredRows = searchTerm ? fuseSearch(ledgerRows, searchTerm) : ledgerRows;

  function fuseSearch(items, searchTerm) {
  const fuse = new Fuse(items, {
    keys: [
      "attributes.provider",
      "attributes.account",
      "attributes.cc_code",
      "attributes.observation",
      "attributes.description",
      "attributes.description_cc"
    ],
    threshold: 0.5,
    minMatchCharLength: 2,
    ignoreLocation: true
  });

  return fuse.search(searchTerm).map(result => result);
}


  const totalDebit = filteredRows.reduce((acc, row) => acc + parseFloat(row?.attributes?.debit || 0), 0);
  const totalCredit = filteredRows.reduce((acc, row) => acc + parseFloat(row?.attributes?.credit || 0), 0);

  

  return (
    <div>
      {props.showIcon && <i className="fa fa-eye" onClick={handleClickOpen} style={{ cursor: 'pointer' }} data-tip="Abrir"></i>}

      {open && (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
          <DialogContent style={{minHeight: "calc(100vh - 164px)"}}>
            <FormControl fullWidth>
              <input
                id="search-provider"
                type="text"
                placeholder="Pesquisar fornecedor..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '100%', padding: '10px' }}
              />
            </FormControl>
            {
              loading ? <center>
              <img src={'/images/Digitando.gif'} style={{width: 150}}></img>
              <div>Carregando...</div>
              </center>
              :
            <>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Conta</TableCell>
                    <TableCell>{filteredRows[0]?.attributes?.account}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>{filteredRows[0]?.attributes?.description}</TableCell>
                  </TableRow>
                </TableBody>
                
              </Table>
              <FastTable 
                filteredRows={filteredRows}
                numberToCurrency={numberToCurrency}
                
              />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    
                    <TableCell style={{width: 123}}>{numberToCurrency(totalCredit - totalDebit)}</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </>
          }
          </DialogContent>
          <DialogActions>
            <Button className="button-app" onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
